import './fileupload.scss';
import React from "react";
import { FileUploader } from 'react-drag-drop-files';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUpload } from '@fortawesome/free-solid-svg-icons'
import { toast } from 'react-toastify';

const FileUpload = ({files, setFiles, disabled}) =>{

    const fileTypes = ["JPG","JPEG", "PNG", "GIF","PDF"];

    const handleChange = (list) => {
        if (list) {
            let ar = [...files];
            for(var i = 0; i < list.length; i++){
                ar.push(list[i]);
            }
            setFiles(ar);
        } 
    };

    const handleSizeError = (file) => {
        if(file.indexOf('small') > -1){
            toast.error('Die Datei ist leider zu klein.');
        }
        if(file.indexOf('big') > -1){
            toast.error('Die Datei ist leider zu groß.');
        }
    };

    return(
        <FileUploader
            children={<UploadDisplay />}
            multiple={true}
            handleChange={handleChange}
            types={fileTypes}
            style={{"width":"100%"}}
            maxSize={31}
            minSize={0.0001}
            onSizeError={(file) => handleSizeError(file)}
            label={'Dateien hier ablegen'}
            classes={'fileupload-area'}
            disabled={disabled ? disabled: false}
        />
    );
}

export const UploadDisplay = () => {
    return (
        <>
            <FontAwesomeIcon icon={faUpload} />
            <div className='wpop-upload-wrapper'>
            <div className="wpop-upload-text">Dateien hier ablegen oder zur Auswahl klicken</div>
            <div className="wpop-file-types">JPG,PNG,PDF - max. 30MB</div>
            </div>
            </>
    ); 
}

export default FileUpload;