import React, { useState, useRef } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import ReCAPTCHA from "react-google-recaptcha";
import { userActions } from "../actions";
import { Spinner } from "./spinner/Spinner";
import { useEffect } from "react";
import bmwi from '../assets/bmwi.png';

export const Login = () => {
  const dispatch = useDispatch();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [showSpinner, setShowSpinner] = useState(false);
  const message = useSelector((state) => state.alert.message);
  const loggingIn = useSelector((state) => state.authentication.loggingIn);
  const captchaRef = useRef();

  useEffect(() => {
    setShowSpinner(loggingIn);
  }, [loggingIn]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    const token = await captchaRef.current.executeAsync();

    if (username && password) {
      setShowSpinner(true);
      dispatch(userActions.login(username, password, token));
    }
  };

  return (
    <div className="container container-small-view">
      <div className="row">
        <div className="col-12 col-sm-12 col-md-10 col-lg-8">
          <h1 style={{ marginBbottom: '0.2em' }}>Anmeldung im Onlineportal <b>Wirtschaftsprüfungsexamen</b></h1>

          <p>Das Onlineportal <b>Wirtschaftsprüfungsexamen</b> ermöglicht allen, die einen <b>neuen Antrag auf Zulassung zum Wirtschaftsprüfungsexamen oder zur Eignungsprüfung</b> stellen wollen, diesen digital zu stellen und die nachfolgende Kommunikation mit der Prüfungsstelle ebenfalls online zu führen.</p>

          <p>Für die Nutzung dieses Angebots müssen Sie sich zunächst einmalig registrieren. Erst nach erfolgreicher Registrierung können Sie das Onlineportal nutzen. Zur Anmeldung geben Sie Ihre E-Mail-Adresse als Kennung und Ihr im Registrierungsprozess selbst gewähltes Passwort ein und klicken Sie auf <b>LOGIN</b>.</p>

          <p>
            Ansprechpartner für technische Fragen zum Onlineportal Wirtschaftsprüfungsexamen ist die processware GmbH
            (<a href="mailto:support@processware.de">support@processware.de</a>,
            Telefon: <a href="tel:+49 221 299 429 84">+49 221 299 429 84</a>).
          </p>

          <p>Bei allen Kandidatinnen und Kandidaten, deren <b>Prüfungsverfahren vor dem Prüfungstermin I/2023</b> begonnen hat, bleibt es zunächst bei der Kommunikation per Post, Fax oder E-Mail.</p>

          <p>Wenn Sie zu dieser Gruppe gehören und sich zu einer oder mehreren weiteren Modulprüfung/en anmelden möchten, finden Sie das Anmeldeformular <a href="https://www.wpk.de/fileadmin/documents/Nachwuchs/Pruefungsstelle/Vordruck-Muster.pdf#page=1" target="_blank">hier</a>. In einem nächsten Entwicklungsschritt sollen auch diese Kandidatinnen und Kandidaten die Möglichkeit erhalten, sich ebenfalls hier zu registrieren und das Onlineportal Wirtschaftsprüfungsexamen zu nutzen.</p>
        </div>
        <div>
          <div className="col-12 col-sm-12 col-md-2 col-lg-2">
            <img src={bmwi} alt="Logo Bundesministerium für Wirtschaft und Energie" className="img-logo-login" style={{ "width": "195px" }} />
          </div>
        </div>
      </div>
      <form onSubmit={(e) => handleSubmit(e)} autoComplete="off">
        <div className="row margin-left-row-login">
          <div style={{ marginBottom: "0.5em", paddingBottom: "1em" }} className="col-12 col-sm-12 col-md-10 col-lg-8 form-Box-Hellblau">
            {!message ? null : (
              <div
                className="row"
                style={{ paddingTop: "20px", paddingBottom: "20px" }}
              >
                <div className="col-12 col-xs-12 col-sm-12 col-md-12 col-lg-12">
                  <span className="pflichtfeld2">{message}</span>
                </div>
              </div>
            )}

            <div className="row">
              <div style={{ paddingTop: "0.7em", paddingBottom: "1em" }} className="col-3 col-xs-4 col-sm-3 col-md-3 col-lg-3">
                E-Mail
              </div>
              <div
                id="loginDiv"
                style={{ paddingTop: "0.7em", paddingBottom: "1em" }}
                className="col-9 col-xs-8 col-sm-9 col-md-6 col-lg-6"
              >
                <input
                  type="text"
                  name="username"
                  onChange={(e) => setUsername(e.target.value)}
                  className="inputText100percent"
                />{" "}
              </div>
            </div>

            <div className="row">
              <div
                style={{ paddingTop: "0.3em" }}
                className="col-3 col-xs-4 col-sm-3 col-md-3 col-lg-3"
              >
                Passwort
              </div>
              <div
                style={{ paddingTop: "0.3em" }}
                className="col-9 col-xs-8 col-sm-9 col-md-6 col-lg-6"
              >
                <input
                  type="password"
                  name="password"
                  onChange={(e) => setPassword(e.target.value)}
                  className="inputText100percent"
                />
              </div>
            </div>

            <div className="row" style={{ 'marginTop': '0.5em' }}>
              <div style={{ paddingTop: "0.5em" }} className="col-3 col-xs-4 col-sm-3 col-md-3 col-lg-3"></div>
              <div
                style={{ paddingTop: "0.3em" }}
                className="col-9 col-xs-8 col-sm-9 col-md-6 col-lg-6">
                <button className="button">Login</button>
              </div>
            </div>

            <div className="row">
              <div className="col-3 col-xs-4 col-sm-3 col-md-3 col-lg-3"></div>
              <div className="col-6 col-xs-8 col-sm-6 col-md-6 col-lg-6">
                <Link to="/examensportal/passwortvergessen" style={{ color: "red" }}><i className="fas fa-caret-right"></i>Passwort vergessen</Link>
              </div>
            </div>
          </div>


          <div style={{ paddingTop: "1em", marginBottom: "3.5em", paddingBottom: "1em" }} className="col-12 col-sm-12 col-md-10 col-lg-8 form-Box-Hellblau">
            <p>
              Falls Sie sich noch nicht registriert haben, können Sie dies hier tun.
            </p>
            <div className="row">
              <div
                style={{ paddingTop: "0.3em" }}
                className="col-3 col-xs-3 col-sm-3 col-md-3 col-lg-3"
              ></div>
              <div
                style={{ paddingTop: "0.3em" }}
                className="col-6 col-xs-7 col-sm-6 col-md-6 col-lg-6"
              >
                <Link to="/examensportal/registrierung" className="button">zur Registrierung</Link>
              </div>
              <div
                style={{ paddingTop: "0.3em" }}
                className="col-1 col-xs-2 col-sm-1 col-md-1 col-lg-1"
              ></div>
            </div>
          </div>

        </div>
        <div className="captcha">
          <ReCAPTCHA
            sitekey={process.env.REACT_APP_RECAPTCHA_SITEKEY}
            size='invisible'
            ref={captchaRef}
          />
        </div>
      </form>
      <Spinner show={showSpinner} />
    </div>
  );
};
